import React, { FC, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import { NPSDataState } from "../../types/npsTypes";
import { barColors } from "./barColors";
import { I18n, Translate } from "react-redux-i18n";

type BarChartWithToggleProps = {
  allData: ChartQuestionAnswersResponseType;
  NPSData?: Array<NPSDataState>;
};

const getAverageScore = (data: ChartQuestionAnswersResponseType) => {
  let totalScore = 0;
  let count = 0;

  data.forEach((question) => {
    question.answers.forEach((answer) => {
      const score = parseFloat(answer.answerMessage);
      if (!isNaN(score)) {
        totalScore += score * answer.answerCount;
        count += answer.answerCount;
      }
    });
  });

  return count > 0 ? totalScore / count : 0;
};

const BarChartWithToggleHorizontal: FC<BarChartWithToggleProps> = ({ allData, NPSData }) => {
  const averageScore = useMemo(() => getAverageScore(allData), [allData]);

  const chartDataState = allData.flatMap((item) =>
    item.answers
      .sort((a, b) => {
        const numA = parseFloat(a.answerMessage);
        const numB = parseFloat(b.answerMessage);

        if (isNaN(numA) && isNaN(numB)) return 0;
        if (isNaN(numA)) return 1;
        if (isNaN(numB)) return -1;

        return numA - numB;
      })
      .map((answer) => {
        if (answer.answerMessage === "..." || answer.answerMessage === "") return null;

        return {
          name: answer.answerMessage,
          total: answer.answerCount,
          percentage: answer.percentage,
        };
      })
      .filter(Boolean),
  );

  if (chartDataState.length === 0) {
    return <div className="text-center p-4">{I18n.t("noData")}</div>;
  }

  const currentNpsData =
    allData[0].templateType === "LOYALTY_INDEX" && NPSData
      ? NPSData.find((item) => Object.keys(item)[0] === allData[0].questionId)?.[allData[0].questionId]
      : null;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {currentNpsData && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            padding: "15px",
            borderRadius: "8px",
            width: "18em",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "bold", color: currentNpsData.indexNps >= 0 ? "green" : "red" }}>
            {currentNpsData.indexNps.toFixed(1)}
          </div>
          <span style={{ color: "#8D8D8D", fontSize: 18, fontWeight: 500 }}>
            <Translate value={"loyaltyIndexClient"} />
          </span>
        </div>
      )}

      {allData[0].templateType === "NPS" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            padding: "15px",
            borderRadius: "8px",
            width: "18em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <span style={{ fontSize: "24px" }}>⭐</span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: averageScore >= 0 ? "green" : "red",
              }}
            >
              {averageScore.toFixed(1)}
            </span>
          </div>
          <span style={{ color: "#8D8D8D", fontSize: 18, fontWeight: 500 }}>
            <Translate value={"averageRating"} />
          </span>
        </div>
      )}

      <ResponsiveContainer width="100%" height={340}>
        <BarChart data={chartDataState}>
          <CartesianGrid strokeDasharray="2" />
          <XAxis dataKey="name" stroke="#000000" />
          <YAxis type="number" stroke="#000000" />
          <Tooltip
            wrapperStyle={{ width: "200px" }}
            contentStyle={{ maxWidth: "500px", whiteSpace: "pre-wrap", wordBreak: "break-word" }}
            formatter={(value, name) => {
              if (name === "total") {
                const entry = chartDataState.find((item) => item?.total === value);
                const percentage = entry?.percentage != null ? entry.percentage.toFixed(1) : "0.0";
                return [`${I18n.t("grade")}: ${value}, ${I18n.t("percentage")}: ${percentage}%`];
              }
              return [`${value}`];
            }}
          />
          <Bar dataKey="total" fill="#00934F" barSize={23} radius={[5, 5, 0, 0]}>
            {chartDataState.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartWithToggleHorizontal;
