import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { I18n, Translate } from "react-redux-i18n";
import { Button, DatePicker, DatePickerProps, Select, Spin } from "antd";
import { useDatesFilter } from "../answersTab/helpers";
import { RangePickerProps } from "antd/es/date-picker";
import { DownloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { POST } from "../../../../../../types/httpMethod";
import { requestApi } from "../../../../../../utils/requestApi";
import {
  ChartQuestionAnswersInfoType,
  ChartQuestionAnswersResponseType,
} from "../../../../../../types/chartQuestionAnswersInfoType";
import { Dayjs } from "dayjs";
import { DATE_TIME_FORMAT } from "../../../../../../utils";
import { useActions } from "../../../../../../hooks/useActions";
import StatisticsLayout from "../../../../../../components/statisticsLayout";
import { chartsMapping } from "../../../../../../components/charts/chartsMapping";
import { NPSDataState } from "../../../../../../types/npsTypes";

const ReportsTab: FC = () => {
  const { id: surveyId } = useParams();
  const survey = useSelector((state: RootState) => state.surveys?.current);
  const [lang, setLang] = useState<string | undefined>();
  const [canApply, setCalApply] = useState(false);
  const [data, setData] = useState<ChartQuestionAnswersResponseType>([]);
  const [npsData, setNpsData] = useState<NPSDataState>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { selectedDates, setSelectedDates, resetDates } = useDatesFilter();
  const { getSurveyReportPdf } = useActions();

  const onDatesPick = (value: DatePickerProps["value"] | RangePickerProps["value"]) => {
    if (value === null) {
      resetDates();
    } else if (Array.isArray(value)) {
      setSelectedDates(value);
    }
    setCalApply(true);
  };

  const loadNPSData = useCallback(
    async (questionId: string, from?: string, to?: string) => {
      try {
        const response = await requestApi(
          POST,
          `/chart/${surveyId}/question/${questionId}/nps`,
          { from, to },
          false,
          false,
        );
        setNpsData((prev) => ({
          ...prev,
          [questionId]: response,
        }));
      } catch (error) {
        console.error("Error loading NPS data:", error);
      }
    },
    [surveyId],
  );

  const loadAllData = useCallback(
    async (from?: string, to?: string) => {
      setIsLoading(true);
      try {
        const chartData = await requestApi(
          POST,
          "/chart/question/answers-info",
          { from, to },
          false,
          false,
          {},
          { surveyId },
        );
        setData(chartData);

        const npsQuestions = chartData.filter(
          (item: ChartQuestionAnswersInfoType) => item.templateType === "LOYALTY_INDEX",
        );

        await Promise.all(
          npsQuestions.map((question: ChartQuestionAnswersInfoType) => loadNPSData(question.questionId, from, to)),
        );
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [surveyId, loadNPSData],
  );

  const applyFilters = useCallback(() => {
    if (canApply) {
      const from = selectedDates[0] ? (selectedDates[0] as Dayjs).format(DATE_TIME_FORMAT) : undefined;
      const to = selectedDates[1] ? (selectedDates[1] as Dayjs).format(DATE_TIME_FORMAT) : undefined;
      loadAllData(from, to).then();
      setCalApply(false);
    }
  }, [selectedDates, canApply, loadAllData]);

  const handleDownload = useCallback(() => {
    if (surveyId) {
      getSurveyReportPdf(surveyId);
    }
  }, [surveyId]);

  useEffect(() => {
    setLang(survey?.languages?.at(0)?.langCode);
  }, [survey?.languages]);

  useEffect(() => {
    loadAllData().then();
  }, [loadAllData]);

  const renderChart = (item: ChartQuestionAnswersInfoType) => {
    const ChartComponent = chartsMapping[item.templateType];
    if (ChartComponent) {
      const chartProps = {
        allData: [item],
        language: lang,
        ...(item.templateType === "LOYALTY_INDEX" && {
          NPSData: Object.keys(npsData).map((key) => ({ [key]: npsData[key] })),
        }),
      };
      return <ChartComponent key={item.questionId} {...chartProps} />;
    }
    return null;
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: 8, width: "100%", marginBottom: 10 }}>
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={onDatesPick}
          value={selectedDates}
          inputReadOnly
        />
        <Button onClick={applyFilters} type={"primary"} style={{ marginRight: 30 }} disabled={!canApply}>
          <Translate value={"display"} />
        </Button>
        <Select
          value={lang}
          style={{ width: 160 }}
          onChange={(value) => setLang(value)}
          placeholder={I18n.t("selectLanguage")}
        >
          {survey?.languages?.map((lang, index) => (
            <Select.Option key={index} value={lang.langCode}>
              {lang.langName}
            </Select.Option>
          ))}
        </Select>
        <Button type="default" icon={<DownloadOutlined />} onClick={handleDownload} style={{ marginLeft: "auto" }}>
          <Translate value="download" />
        </Button>
      </div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
          {" "}
          <Spin />
        </div>
      ) : (
        <>
          {data.map((item, index) => {
            const chart = renderChart(item);
            if (chart) {
              return (
                <StatisticsLayout key={item.questionId} title={index + 1 + ": " + item.questionName}>
                  {chart}
                </StatisticsLayout>
              );
            }
            return null;
          })}
          {data.length === 0 && <p>No data available.</p>}
        </>
      )}
    </div>
  );
};

export default ReportsTab;
