import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FileFormat } from "../../types/fileFormatEnum";
import { RcFile } from "antd/es/upload";
import { Alert, Button, Drawer, Form, message, Modal, Radio, Upload } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { uploadContactsFile } from "../../store/slices/contacts/contactsService";
import { AxiosError } from "axios";
import { InboxOutlined } from "@ant-design/icons";
import { ContactType } from "../../types/contactType";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useActions } from "../../hooks/useActions";
import { DATE_FORMAT } from "../../utils";
import { ContactsAttributeDatatypeEnum } from "../../types/ContactsAttributeType";
import dayjs from "dayjs";
import InputFormItemComponent from "../../components/form/items/input";
import HiddenValueFormItemComponent from "../../components/form/items/hiddenValue";
import DatepickerFormItemComponent from "../../components/form/items/datepicker";

const grid_gap = { display: "grid", gap: 16 };

type UploadModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

export const UploadModal: FC<UploadModalProps> = ({ open, onClose }) => {
  const [fileType, setFileType] = useState<FileFormat>(FileFormat.EXCEL);
  const [file, setFile] = useState<RcFile | undefined>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>();
  const { getContacts } = useActions();

  const getFileType = useCallback(
    (rcFile?: RcFile) => {
      return (rcFile || file)?.name.split(".").at(-1);
    },
    [file],
  );

  const uploadFile = useCallback(
    (rcFile: RcFile) => {
      const type = getFileType(rcFile);

      if (type !== fileType && !error) {
        return setError(true);
      } else if (type === fileType && error) {
        setError(false);
      }
      setFile(rcFile);
    },
    [fileType, error],
  );

  const onSubmit = useCallback(() => {
    const type = getFileType();
    if (type !== FileFormat.CSV && type !== FileFormat.EXCEL) {
      return message.error(I18n.t("noFileSelected"));
    }
    if (file) {
      setLoading(true);
      uploadContactsFile(type, file)
        .then(() => {
          if (error) setError(false);
          getContacts();
          message.success(I18n.t("fileUploaded")).then();
          onRemove();
          onClose();
        })
        .catch((e: AxiosError) => {
          if (!error) setError(true);
          setErrorText(e.message);

          if (e.response?.status === 403) {
            message.error(I18n.t("youHaveReachedMaxContacts"));
          } else {
            message.error(I18n.t("fileUploadingError")).then();
          }
        })
        .finally(() => setLoading(false));
    }
  }, [file]);

  useEffect(() => {
    if (!open) {
      setError(false);
      setFile(undefined);
      setFileType(FileFormat.CSV);
    }
  }, [open]);

  const onRemove = useCallback(() => {
    setFile(undefined);
    if (error) setError(false);
    if (errorText) setErrorText(undefined);
  }, [error, errorText]);

  return (
    <Modal closable width={700} footer={null} open={open} onCancel={onClose} title={I18n.t("contactsUploading")}>
      <div style={{ padding: "0 20px", maxWidth: "inherit" }}>
        <Alert
          message={<Translate value={"warningWhenUploadingFile.title"} style={{ fontWeight: "bold" }} />}
          description={<Description />}
          style={{ marginBottom: 24, backgroundColor: "#ADD8E6" }}
          type={"error"}
        />

        <div style={{ ...grid_gap, marginTop: 48 }}>
          <span style={{ fontWeight: 600 }}>
            <Translate value={"filesType"} />
          </span>
          <Radio.Group
            value={fileType}
            onChange={({ target }) => {
              setFileType(target.value);
              onRemove();
            }}
            style={{ ...grid_gap, marginBottom: 24 }}
            options={[{ label: I18n.t("contactsUploadXlsxHelpText"), value: FileFormat.EXCEL }]}
          />
        </div>

        <div style={{ ...grid_gap }} className={"contact-filters-upload-list-styles"}>
          <span style={{ fontWeight: 600 }}>
            <Translate value={"source"} />
          </span>
          <Upload.Dragger
            maxCount={1}
            onRemove={onRemove}
            listType={"picture"}
            accept={`.${fileType}`}
            beforeUpload={uploadFile}
            fileList={file ? [file] : []}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ fontSize: 48 }} />
            </p>
            <p className="ant-upload-text">
              <Translate value={"selectFile"} />
            </p>
          </Upload.Dragger>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "90px",
          }}
        >
          <Button type={"primary"} onClick={onSubmit} loading={loading}>
            <Translate value={"upload"} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const Description: FC = () => {
  const { getExampleFile } = useActions();

  return (
    <div style={{ display: "grid" }}>
      <span style={{ marginTop: 10, marginBottom: 24 }}>
        <Translate value={"warningWhenUploadingFile.subTitle"} />
      </span>
      <Translate value={"warningWhenUploadingFile.first"} />
      <Translate value={"warningWhenUploadingFile.second"} />
      <Translate value={"warningWhenUploadingFile.third"} />
      <span>
        <Translate value={"warningWhenUploadingFile.fourth"} /> (
        <Translate value={"watchShort"} />
        <button
          onClick={getExampleFile}
          style={{
            textDecoration: "underline",
            background: "none",
            border: "none",
            color: "green",
            cursor: "pointer",
            display: "inline",
          }}
        >
          <Translate value={"exampleHere"} />
        </button>
        )
      </span>
    </div>
  );
};

type ManuallyAddFormDrawerProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

export const ManuallyAddFormDrawer: FC<ManuallyAddFormDrawerProps> = (props) => {
  const { open, onClose } = props;
  const [form] = Form.useForm<ContactType>();
  const { current: currentContact, attributesList } = useSelector((state: RootState) => state.contacts);
  const { setCurrentContact, createContact, updateContact } = useActions();

  const isEditing = useMemo(() => {
    return !!currentContact?.contactId;
  }, [currentContact]);

  const onCancel = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({ properties: attributesList });
    setCurrentContact(undefined);
    onClose();
  }, [form, attributesList]);

  const onSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        const dto = toContactDto(values);
        if (!values?.contactId) createContact(dto);
        else updateContact({ contactId: values.contactId, values: dto });
        onCancel();
      })
      .catch(console.log);
  }, [form]);

  useEffect(() => {
    const values = attributesList.map((attr) => {
      if (!!currentContact?.properties) {
        const found = currentContact?.properties?.find((it) => it.propertyKey === attr.propertyKey);
        if (!!found?.value && attr.dataType === ContactsAttributeDatatypeEnum.DATE) {
          return {
            ...attr,
            value: dayjs(found.value) as any,
          };
        }
        return { ...attr, value: found?.value ?? "" };
      }
      return attr;
    });
    form.setFieldsValue({ contactId: currentContact?.contactId, name: currentContact?.name, properties: values });
  }, [currentContact, form]);

  useEffect(() => {
    form.setFieldsValue({ properties: attributesList });
  }, [attributesList]);

  return (
    <Drawer
      title={I18n.t(isEditing ? "updateContact" : "newContact")}
      onClose={onCancel}
      placement="right"
      size={"large"}
      open={open}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, padding: "10px 0" }}>
          <Button type="primary" onClick={onSubmit}>
            <Translate value={"save"} />
          </Button>
          <Button onClick={onCancel}>
            <Translate value={"cancel"} />
          </Button>
        </div>
      }
    >
      <Form form={form} layout={"vertical"}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <InputFormItemComponent name={"name"} label={I18n.t("fullName")} style={{ width: 330 }} />
          <HiddenValueFormItemComponent name={"contactId"} />
          {!!attributesList?.length && (
            <Form.List name={"properties"}>
              {(fields) => {
                return (
                  <>
                    {fields.map(({ key: index }) => {
                      const datatype = form.getFieldValue(["properties", index, "dataType"]);
                      const label = form.getFieldValue(["properties", index, "description"]);

                      const rules = [];
                      if (datatype === ContactsAttributeDatatypeEnum.EMAIL) {
                        rules.push({
                          type: "email",
                          message: I18n.t("emailError"),
                        });
                      } else if (datatype === ContactsAttributeDatatypeEnum.PHONE) {
                        rules.push({
                          pattern: new RegExp(/^[0-9][\s]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im),
                          message: I18n.t("phoneError"),
                        });
                      }

                      return (
                        <div key={index}>
                          <HiddenValueFormItemComponent name={[index, "propertyKey"]} />
                          <HiddenValueFormItemComponent name={[index, "dataType"]} />
                          {datatype === ContactsAttributeDatatypeEnum.DATE ? (
                            <DatepickerFormItemComponent
                              label={label}
                              required={false}
                              disablePast={false}
                              hasFeedback={false}
                              style={{ width: 330 }}
                              name={[index, "value"]}
                            />
                          ) : (
                            <InputFormItemComponent
                              type={
                                datatype === ContactsAttributeDatatypeEnum.INTEGER ||
                                datatype === ContactsAttributeDatatypeEnum.DECIMAL
                                  ? "number"
                                  : "string"
                              }
                              name={[index, "value"]}
                              style={{ width: 330 }}
                              required={false}
                              label={label}
                              rules={rules}
                              prefix={datatype === ContactsAttributeDatatypeEnum.PHONE ? "+" : undefined}
                              handlePhoneInputChange={
                                datatype === ContactsAttributeDatatypeEnum.PHONE ? true : undefined
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </Form.List>
          )}
        </div>
      </Form>
    </Drawer>
  );
};

const toContactDto = (contact: ContactType) => {
  if (!contact?.properties?.length) {
    return { ...contact, properties: [] };
  }
  return {
    ...contact,
    properties: contact.properties
      .map((it) =>
        it.dataType !== ContactsAttributeDatatypeEnum.DATE || !it.value
          ? it
          : { ...it, value: dayjs(it.value).format(DATE_FORMAT) },
      )
      .map((it) => {
        if (it.dataType === ContactsAttributeDatatypeEnum.PHONE && it.value && !it.value.startsWith("+")) {
          return {
            ...it,
            value: `+${it.value}`,
          };
        }
        return {
          propertyKey: it.propertyKey,
          dataType: it.dataType,
          value: it.value,
        };
      }),
  };
};
