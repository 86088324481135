import { TemplateEnum } from "../../types/templateEnum";
import BarChartWithToggleVertical from "./barChartNoPaddingVertical";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import { NPSDataState } from "../../types/npsTypes";
import BarChartWithToggleHorizontal from "./barChartWithToggleHorizontal";

type ChartComponentProps = {
  allData: ChartQuestionAnswersResponseType;
  language?: string | undefined;
  NPSData?: Array<NPSDataState>;
};

type ChartMappingType = {
  [key in TemplateEnum]?: React.FC<ChartComponentProps>;
};

export const chartsMapping: ChartMappingType = {
  MULTIPLE_CHOICE: BarChartWithToggleHorizontal,
  SINGLE_CHOICE: BarChartWithToggleHorizontal,
  LOYALTY_INDEX: BarChartWithToggleVertical,
  NPS: BarChartWithToggleVertical,
};
