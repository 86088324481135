import React, { FC, useState } from "react";
import { Form } from "antd";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../../../../utils";
import { GrowingList } from "../helpers";
import { I18n } from "react-redux-i18n";
import Divider from "../../../../../../components/divider";
import { SurveyLanguage } from "../../../../../../types/language";
import useSurveyDisabled from "../../../../../../hooks/useSurveyDisabled";
import CustomTemplate from "./custom";
import SelectFormItemComponent from "../../../../../../components/form/items/select";

type LimitedRangingProps = {
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
};

const LimitedRangingTemplate: FC<LimitedRangingProps> = (props) => {
  const disabled = useSurveyDisabled();
  const [fieldsLength, setFieldsLength] = useState(0);

  return (
    <>
      <div>
        <Form.Item
          name={["answerTemplate"]}
          label={I18n.t("numberOfAvailableAssessments")}
          rules={[{ required: true, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
        >
          <div>
            <SelectFormItemComponent
              noStyle
              name={["answerTemplate", "numberOfAssessments"]}
              values={Array.from({ length: fieldsLength }, (_, i) => ({
                label: i + 1,
                value: i + 1,
              }))}
              style={{ width: "8em" }}
            />
          </div>
        </Form.Item>
      </div>
      <div className={"no-error-label"}>
        <Divider translationCode={"criteria"} />
        <GrowingList
          {...props}
          disabled={disabled}
          dataKey={"criteria"}
          addButtonLabel={I18n.t("addCriteria")}
          maxFields={30}
          onListLengthChange={setFieldsLength}
        />
        <CustomTemplate />
      </div>
    </>
  );
};

export default LimitedRangingTemplate;
