import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import removeMarkdown from "remove-markdown";

interface MarkdownParserProps {
  mixedString: string;
  style?: React.CSSProperties;
  stripMarkdown?: boolean;
}

const MarkdownParser = ({ mixedString, style, stripMarkdown }: MarkdownParserProps) => {
  const processedString = stripMarkdown ? removeMarkdown(mixedString) : mixedString;

  return (
    <span style={style} className="markdown">
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ children }) => <span>{children}</span>,
        }}
      >
        {processedString}
      </ReactMarkdown>
    </span>
  );
};

export default MarkdownParser;
