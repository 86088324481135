export const barColors = [
  "#6EB5D5",
  "#789FE0",
  "#7780E0",
  "#8067DC",
  "#A367DC",
  "#CC78DF",
  "#DF78D4",
  "#DF78B3",
  "#DF7895",
  "#E07A77",
  "#C98161",
  "#DFB878",
  "#DFD679",
  "#CADF78",
  "#95CB63",
  "#8CDF78",
  "#7ADF85",
  "#7ADFA3",
  "#7AE0C2",
  "#79DFDF",
  "#78BFDF",
  "#789FE0",
  "#7780E0",
  "#8D77E0",
  "#AD77DF",
  "#CC78DF",
  "#DF78D4",
  "#DF78B3",
  "#DF7895",
  "#E07A77",
  "#E09777",
];
