import React, { FC, useRef, useState } from "react";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import "./index.scss";
import { Button, Form, Popover } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { REQUIRED_MESSAGE } from "../../utils/getRequiredMessage";

type Props = {
  readonly value?: string;
  readonly onChange?: (value: string) => void;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly name: string | (string | number)[];
  readonly label?: string;
  readonly showEmoji?: boolean;
  readonly rows?: number;
  readonly toolbarContents?: () => React.ReactNode;
};

const MdxEditor: FC<Props> = ({ value = "", onChange, disabled, showEmoji, rows, toolbarContents }) => {
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<any>(null);

  const handleMarkdownChange = (newValue: string) => {
    if (disabled) return;
    onChange?.(newValue);
  };

  const handleEmojiSelect = (emoji: any) => {
    if (editorRef.current && !disabled) {
      const newMarkdownValue = value + emoji.native;
      handleMarkdownChange(newMarkdownValue);
      editorRef.current.setMarkdown(newMarkdownValue);
    }
  };

  const defaultToolbarContents = () => (
    <>
      <UndoRedo />
      <BoldItalicUnderlineToggles />
      <ListsToggle />
      <BlockTypeSelect />
    </>
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1 }}>
        <MDXEditor
          ref={editorRef}
          markdown={value}
          contentEditableClassName="contentEditable"
          onChange={handleMarkdownChange}
          className={`editor-container ${disabled ? "editor-disabled" : ""} ${rows ? `rows-${rows}` : ""}`}
          plugins={[
            headingsPlugin(),
            listsPlugin(),
            toolbarPlugin({
              toolbarContents: toolbarContents || defaultToolbarContents,
            }),
          ]}
        />
      </div>
      {showEmoji && (
        <Popover
          content={
            <div ref={emojiPickerRef}>
              <Picker data={data} onEmojiSelect={handleEmojiSelect} locale={"ru"} position={"none"} theme={"light"} />
            </div>
          }
          title="Выберите эмодзи"
          trigger="click"
          open={emojiPickerVisible}
          onOpenChange={setEmojiPickerVisible}
        >
          <Button type="text" icon={<SmileOutlined />} onClick={() => setEmojiPickerVisible(!emojiPickerVisible)} />
        </Popover>
      )}
    </div>
  );
};

const FormItemMdxEditor: FC<Props> = ({ name, label, required, ...props }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required, message: REQUIRED_MESSAGE() }]}>
      <MdxEditor name={""} {...props} />
    </Form.Item>
  );
};

export default FormItemMdxEditor;
